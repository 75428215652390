import { FormGroup } from "@angular/forms";
import {
  ColumnMenuSettings as KendoColumnMenuSettings,
  FilterableSettings as KendoFilterableSettings, GridDataResult as KendoGridDataResult, GridNavigableSection as KendoGridNavigableSection,
  GridNavigableSettings as KendoGridNavigableSettings,
  GroupableSettings as KendoGroupableSettings, MultipleSortSettings as KendoMultipleSortSettings, PagerSettings as KendoPagerSettings,
  RowArgs as KendoRowArgs,
  RowClassArgs as KendoRowClassArgs,
  RowSelectedFn as KendoRowSelectedFn,
  ScrollMode as KendoScrollMode,
  SelectableSettings as KendoSelectableSettings,
  SingleSortSettings as KendoSingleSortSettings, SortSettings as KendoSortSettings,
  CellClickEvent as KendoCellClickEvent
} from "@progress/kendo-angular-grid";
import {
  AggregateDescriptor as KendoAggregateDescriptor,
  CompositeFilterDescriptor as KendoCompositeFilterDescriptor,
  FilterDescriptor as KendoFilterDescriptor,
  GroupDescriptor as KendoGroupDescriptor,
  SortDescriptor as KendoSortDescriptor,
  State as KendoGridState
} from "@progress/kendo-data-query";
import { DetailTemplateShowIfFn as KendoDetailTemplateShowIfFn } from "@progress/kendo-angular-grid";
import { ColumnConfiguration } from "./column";
import { GridColumnDef } from "../../models/grid-column-def";

export interface GridDataResult extends KendoGridDataResult {}

export interface ColumnMenuSettings extends KendoColumnMenuSettings {}

export interface AggregateDescriptor extends KendoAggregateDescriptor {
  label: string;
  numberFormat?: {
    required: boolean;
    locale?: string;
  };
  format?: string;
  aggregateFn?: Function;
}

export interface State extends KendoGridState {
  aggregates: AggregateDescriptor[]
}

export interface SortDescriptor extends KendoSortDescriptor {}

export interface CompositeFilterDescriptor
  extends KendoCompositeFilterDescriptor {}

export interface GroupDescriptor extends KendoGroupDescriptor {}

export interface FilterDescriptor extends KendoFilterDescriptor {}

export type FilterableSettings = KendoFilterableSettings;

export type GroupableSettings = KendoGroupableSettings;

export type GridNavigableSettings = KendoGridNavigableSettings;

export type GridNavigableSection = KendoGridNavigableSection;

export interface PagerSettings extends KendoPagerSettings {}

export interface RowClassArgs extends KendoRowClassArgs {}

export type RowClassFn = (context: RowClassArgs) => string;

export type ScrollMode = KendoScrollMode;

export interface SelectableSettings extends KendoSelectableSettings {};

export type SingleSortSettings = KendoSingleSortSettings;

export type MultipleSortSettings = KendoMultipleSortSettings;

export type SortSettings = KendoSortSettings;

export type CreateFormGroupFn = (dataItem: any) => FormGroup;

export type QuickAddFn = () => any;

export type CopyFn = (dataItem: any) => any;

export interface GridFormValidationResult {
  isValid: boolean;
  errors: { [key: string]: any }[];
}

export type RowDeletableFn = (dataItem: any) => boolean;

export type RowEditableFn = (dataItem: any) => boolean;

export type RowSelectedFn = KendoRowSelectedFn;

export interface RowArgs extends KendoRowArgs {}

export type DetailTemplateShowIfFn = KendoDetailTemplateShowIfFn;

export interface CellClickEvent extends KendoCellClickEvent {}

export interface CellDoubleClickEvent {
  dataItem: any;
  column: ColumnConfiguration | GridColumnDef;
  rowIndex: number
}

export interface ExportPDFResponse {
  data: any[];
  footerData: any[];
}
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
    EntityCollectionServiceBase,
    EntityCollectionServiceElementsFactory
} from '@ngrx/data';
import { map, Observable, of, switchMap, } from 'rxjs';
import { INDEXOFCUSTOMERS } from '../entity-meta-data/entity-metadata';
import { StaticResponse } from '../model/base-response';
import { IndexOfCustomersInterface } from '../model/indexofcustomers.interface';
import { indexofCustomersUrl, pdfUrl } from '../../../api-endpoints';
import {DomSanitizer, SafeResourceUrl} from "@angular/platform-browser";

@Injectable({ providedIn: 'root' })
export class IndexOfCustomersService extends EntityCollectionServiceBase<IndexOfCustomersInterface> {
    private headers = new HttpHeaders({
        'accept': 'application/json',
    });
    constructor(serviceElementsFactory: EntityCollectionServiceElementsFactory, private http: HttpClient,private sanitizer: DomSanitizer) {
        super(INDEXOFCUSTOMERS,serviceElementsFactory);
    }

    fetchFileData(params: any,fileType:string): Observable<any> {
        const url = `${indexofCustomersUrl}/${fileType}`.replace('{location}', params.location);
        return  this.http.get<any>(url, {params, headers: this.headers})
            .pipe(map((res: any) => {
                return res.data;
            }));
    }

    fetchPdfFileData(fileName: string, location: string, fileLocation?: string): Observable<any> {
        const url = pdfUrl.replace('{location}', location) + fileName;
        const headers = new HttpHeaders({
            'accept': 'application/json'
        });
        const params: any = {};
        if (fileLocation) {
            params.fileLocation = fileLocation;
        }
        else{
            params.fileLocation = "";
        }
        return this.http.get<StaticResponse<Array<any>>>(url, { headers, params })
            .pipe(map((res: StaticResponse<Array<any>>) => {
                return res.data;
            }));
    }

    fetchImageFileData(fileName: string, location: string, fileLocation?: string): Observable<SafeResourceUrl> {
        const url = pdfUrl.replace('{location}', location) + fileName;
        const headers = new HttpHeaders({
            'accept': 'application/json'
        });
        const params: any = {};
        if (fileLocation) {
            params.fileLocation = fileLocation;
        } else {
            params.fileLocation = "";
        }
        return this.http.get(url, { headers: this.headers, params, responseType: 'text' })
            .pipe(
                switchMap((html: string) => {
                    const base64String = this.extractBase64Data(html);
                    const blob = this.base64ToBlob(base64String, 'image/png');
                    const url = URL.createObjectURL(blob);
                    const sanitizedUrl = this.sanitizer.bypassSecurityTrustResourceUrl(url);
                    return of(sanitizedUrl);
                }),
            )
    }

    private extractBase64Data(html: string): string {
        const json = JSON.parse(html);
        return json.data[0];
    }

    private base64ToBlob(base64: string, contentType: string): Blob {
        const byteCharacters = atob(base64);
        const byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        return new Blob([byteArray], { type: contentType });
    }
}
import { Component, OnInit, OnDestroy, Output, EventEmitter } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { Subject, takeUntil } from "rxjs";
import { getTariffPDFMap } from "../constant";
import { IndexOfCustomersService } from "../store/services/index-of-customers.service";
import { Title } from "@angular/platform-browser";
import {environment} from "../../environments/environment.ditest";

@Component({
  selector: "williams-ui-platform-pdf-viewer",
  templateUrl: "./pdf-viewer.component.html",
  styleUrls: ["./pdf-viewer.component.scss"],
})
export class PdfViewerComponent implements OnInit, OnDestroy {
  @Output() pdfViewerShown = new EventEmitter<boolean>();
  pageNumber: number | undefined = 1;
  indexOfCustomer = "IndexofCustomers";
  locations = "Locations";
  destroy$: Subject<boolean> = new Subject<boolean>();
  pdfUrl = "";
  showPdf = false;
  fileName = "";

  constructor(
      private route: ActivatedRoute,
      private customerService: IndexOfCustomersService,
      private title: Title,
  ) {
    this.route.queryParams.subscribe((params) => {
      if (Object.keys(params).length) {
        this.fileName = params["fileName"];
        const condition = environment.fileLocation;
        const TariffPDFMap = getTariffPDFMap(condition);
        this.pageNumber = TariffPDFMap.get(params["title"]);
        this.title.setTitle(this.fileName);
        if (
            this.fileName === this.indexOfCustomer ||
            this.fileName === this.locations
        ) {
          this.showLocationIndexPdf();
        } else {
          this.showPdfFile(this.fileName);
        }
      }
    });
  }

  ngOnInit(): void {}

  showPdfFile(fileName: string) {
    this.customerService
        .fetchPdfFileData(fileName, environment.fileLocation)
        .pipe(takeUntil(this.destroy$))
        .subscribe((res: any) => {
          this.showPdf = true;
          this.pdfUrl = res[0];
        });
  }

  showLocationIndexPdf() {
    this.customerService
        .fetchFileData({ fileType: "PDF", location: environment.fileLocation }, this.fileName)
        .pipe(takeUntil(this.destroy$))
        .subscribe((res: any) => {
          this.showPdf = true;
          this.pdfUrl = res[0];
        });
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }
}
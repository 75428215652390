

export const environment = {
    production: true,
    apiUrlPrefix: '',
    federationAPI_URL: '/assets/config/qa/module-federation.manifest.json',
    commonConfigURL: '/assets/config/common-config-data.json',
    configURL: '/assets/config/qa/config-data.json',
    clientErrorURL: '/assets/config/client-error-data.json',
    fileLocation: 'Dauphin',
    EBBName: 'Dauphin Island Gas Transmission LLC',
    MapName: 'Dauphin',
    ShortName: 'Dauphin',
    ID: '4',
    shortAddress1: 'Dauphin Island Gas Transmission LLC',
    shortAddress2: '1234 Temp Address, Level 3, (dauphin)',
    shortAddress3: 'Houston, Texas 77056 (dauphin)',
    shortAddress4: 'P.O.Box 1396, Houston, Texas 77251-1396 (dauphin)',
    longAddress1: '2800 Post Oak Boulevard, Level 3, Houston, Texas 77056 (dauphin)',
    longAddress2: 'P.O.Box 1396, Houston, Texas 77251-1396 (dauphin)',
    CompanyName: 'Dauphin Island Gas Transmission LLC',
};
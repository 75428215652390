import {
  Component,
  ContentChild,
  EventEmitter,
  forwardRef,
  Input, OnChanges,
  OnInit, Optional,
  Output, SimpleChanges, ViewChild,
} from "@angular/core";
import {
  DropDownFillMode,
  DropDownRounded,
  DropDownSize,
  ItemArgs,
  ItemDisabledFn, MultiColumnComboBoxComponent,
  PopupSettings,
  VirtualizationSettings,
} from "@progress/kendo-angular-dropdowns";
import { Observable } from "rxjs";
import { calculateTextWidth } from "../../../utils/text-width";
import { ComboboxColumnDefinition } from "./combobox-column";
import { MultiColumnComboBoxTemplatesDirective } from "./directives";
import {BaseAccessor} from "../base/base-accessor";
import {ControlContainer, NG_VALUE_ACCESSOR} from "@angular/forms";

const SIZES = {
  small: {
    fontSize: 14,
    spacing: 18 // Padding + border
  },
  medium: {
    fontSize: 14,
    spacing: 18
  },
  large: {
    fontSize: 16,
    spacing: 18
  },
  none: {
    fontSize: 14,
    spacing: 18
  }
};

const BUFFERSPACE = 60;
@Component({
  selector: "williams-ui-platform-multi-column-combobox",
  templateUrl: "./multi-column-combobox.component.html",
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => MultiColumnCombobox),
      multi: true
    }
  ]
})
export class MultiColumnCombobox extends BaseAccessor implements OnInit, OnChanges {
  @ViewChild('combobox', { static: false }) combobox!: MultiColumnComboBoxComponent;
  @Input() allowCustom = false;
  @Input() clearButton = false;
  @Input() data: any;
  @Input() disabled = false;
  @Input() fillMode: DropDownFillMode = "outline";
  @Input() filterable = false;
  @Input() itemDisabled!: ItemDisabledFn;
  @Input() listHeight: number = 200;
  @Input() loading = false;
  @Input() placeholder = "";
  @Input() popupSettings: PopupSettings = {};
  @Input() readonly = false;
  @Input() rounded: DropDownRounded = "none";
  @Input() showError: boolean = false;
  @Input() size: DropDownSize = "medium";
  @Input() suggest = false;
  @Input() textField!: string;
  @Input() value: any;
  @Input() valueField!: string;
  @Input() valueNormalizer!: (text: Observable<string>) => Observable<any>;
  @Input() valuePrimitive = false;
  @Input() virtual: boolean | VirtualizationSettings = false;
  @Input() elementId!: string;
  @Input() autoAdjustWidth = false; // Auto adjust combobox width based on selected value
  protected isVirtual: boolean | VirtualizationSettings = false;

  @Input() columnDefinitions!: ComboboxColumnDefinition[];

  width = "100%";

  @ContentChild(
    forwardRef(() => MultiColumnComboBoxTemplatesDirective)
  )
  multiColumnComboBoxTemplates: any;

  // Fires each time an item selection is changed
  @Output() selectionChange: EventEmitter<any> = new EventEmitter();
  // Fires after the popup has been opened.
  @Output() opened: EventEmitter<any> = new EventEmitter();
  // Fires after the popup has been closed.
  @Output() closed: EventEmitter<any> = new EventEmitter();
  // Fires each time the user types in the input field. You can filter the source based on the passed filtration value
  @Output() filterChange: EventEmitter<any> = new EventEmitter();
  // Fires each time the value is changed
  @Output() valueChange: EventEmitter<any> = new EventEmitter();

  constructor(@Optional() public override controlContainer: ControlContainer) {
    super(controlContainer)
    this.itemDisabledCallback = this.itemDisabledCallback.bind(this);
    this.valueNormalizerCallback = this.valueNormalizerCallback.bind(this);
  }

  override ngOnInit() {
    super.ngOnInit();
    if(this.value) {
      this._setComponentWidth(this.value);
    }
    this.isVirtual = this.virtual ? this.virtual : this.data.length > 1000;
  }

  onSelectionChange(event: any): void {
    this.selectionChange.emit(event);
  }

  onOpened(event: any): void {
    this.opened.emit(event);
  }

  onClosed(event: any): void {
    this.closed.emit(event);
  }

  onFilterChange(event: any): void {
    this.filterChange.emit(event);
  }

  onValueChange(event: any): void {
    this._setComponentWidth(event);
    this.valueChange.emit(event);
  }

  private _setComponentWidth(selectedValue: any): void {
    if(!this.autoAdjustWidth){
      return;
    }

    const displayValue: string = selectedValue[this.textField] ?? '';
    const textWidth = calculateTextWidth(displayValue, `400 ${SIZES[this.size].fontSize}px Helvetica`) + SIZES[this.size].spacing;
    this.width = `${textWidth + BUFFERSPACE}px`;
  }

  itemDisabledCallback(event: ItemArgs): boolean {
    if(this.itemDisabled) {
      return this.itemDisabled(event);
    }
    return false;
  }

  valueNormalizerCallback(text$: Observable<string>): Observable<any> {
    if(this.valueNormalizer) {
      return this.valueNormalizer(text$);
    }
    return text$;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes['data'] && !changes['data'].isFirstChange() && (!changes['data'].previousValue || changes['data'].previousValue?.length === 0)) {
      if(!this.isVirtual) {
        if(this.combobox && this.combobox.isOpen) {
          this.combobox.togglePopup(false);
          this.isVirtual = this.virtual ? this.virtual : changes['data'].currentValue.length > 1000;
          setTimeout(() => this.combobox.togglePopup(true));
        } else {
          this.isVirtual = this.virtual ? this.virtual : changes['data'].currentValue.length > 1000;
        }
      }
    }
  }
}

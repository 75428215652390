import {Component, ElementRef, HostListener, OnInit, ViewChild} from '@angular/core';
import { Router } from '@angular/router';
import { Subject, takeUntil } from 'rxjs';
import { SiteMap } from '../../store/model/site-map.interface';
import { SiteMapService } from '../../store/services/site-map.service';
import {environment} from "../../../environments/environment";

@Component({
  selector: 'williams-ui-platform-site-map',
  templateUrl: './site-map.component.html',
  styleUrls: ['./site-map.component.scss']
})
export class SiteMapComponent implements OnInit {
  @ViewChild('linkContainer') linkContainer!: ElementRef;
  siteMapdata!: any;
  destroy$: Subject<boolean> = new Subject<boolean>();
  layoutRows: any[][] = [];

  @HostListener('window:resize', ['$event'])
  onResize(event:any) {
    this.calculateLinkLayout();
  }

  constructor(private siteMapService: SiteMapService,private router: Router) { }

  ngOnInit(): void {
    this.getSiteMapData();
  }

  getSiteMapData() {
    this.siteMapService.getSiteMapData()
      .pipe(takeUntil(this.destroy$))
      .subscribe((res: SiteMap) => {
        this.siteMapdata = res;
        this.filterAndReplaceUrl('discovery_trf.pdf', `${environment.tariffFile}`);
        this.filterAndReplaceUrl('Discovery_Map.pdf', `${environment.fileLocation}_Map.pdf`);
        //this.filterSiteMapData(); If you ever need a conditional menu item
        this.calculateLinkLayout();
      })
  }

  navigateUrl(val:any){
      this.router.navigateByUrl(`/${val}`);
  }

  filterAndReplaceUrl(oldFileName: string, newFileName: string) {
    this.siteMapdata = this.siteMapdata.map((item: any) => {
      if (item.siteMapList && Array.isArray(item.siteMapList)) {
        item.siteMapList = item.siteMapList.map((subItem: any) => {
          if (subItem.link.includes(oldFileName)) {
            subItem.link = subItem.link.replace(oldFileName, newFileName);
          }
          return subItem;
        });
      }
      return item;
    });
  }

// filterSiteMapData() {
//   if (environment.showContacts === 'false') {
//     this.siteMapdata = this.siteMapdata.filter((item: any) => item.menu !== 'Contacts');
//     }
//   }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  calculateLinkLayout() {
    if(this.linkContainer) {
      const totalWidth = this.linkContainer.nativeElement.offsetWidth - 64; // subtract padding from div width
      const linksInRow = Math.floor(totalWidth / 295);
      const totalRows = Math.ceil(this.siteMapdata.length / linksInRow);
      this.layoutRows = [];
      for(let i = 0; i < totalRows; i++) {
        this.layoutRows.push(this.siteMapdata.slice(i * linksInRow, (i + 1) * linksInRow));
      }
    }
  }
}

import { format, formatDate } from "@progress/kendo-intl";
import {
  firstDayOfMonth as kendoFirstDayOfMonth,
  lastDayOfMonth as kendoLastDayOfMonth,
} from "@progress/kendo-date-math";
export const firstDayOfMonth = (date: Date) => {
  return kendoFirstDayOfMonth(date);
};

export const lastDayOfMonth = (date: Date) => {
  return kendoLastDayOfMonth(date);
};

export const kendoDateFormatter = (value: Date, dateFormat: string) => {
  return formatDate(value,dateFormat);
}

import { Component, OnInit, OnDestroy } from "@angular/core";
import { Router } from "@angular/router";
import { formatDate, Location } from "@angular/common";
import { Subject, takeUntil } from "rxjs";
import { PDF_VIEWER } from "./constant";
import {environment} from "../environments/environment";

@Component({
  selector: "williams-ui-platform-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent implements OnInit, OnDestroy {
  showHomePage = true;
  showPdf = false;
  destroy$: Subject<boolean> = new Subject<boolean>();
  date = formatDate(new Date(), 'MM/dd/yyyy', 'en-US');
  ebbName: string = environment.EBBName;
  companyName = environment.CompanyName;
  constructor(
      private router: Router,
      private location: Location,
  ) {
    this.showHomePage = this.location.path().split('?')[0] === `/${PDF_VIEWER}` ? false : true;
  }

  //All this does is set the icon in the upper tab. It is set up to either be discovery or blank, depending on the environment.
  ngOnInit(): void {
    const link: HTMLLinkElement = document.createElement('link');
    link.rel = 'icon';
    link.type = 'image/x-icon';
    link.href = environment.headerIcon === 'true' ? 'favicon.ico' : 'data:,';
    document.head.appendChild(link);
    document.title = this.companyName;
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }
}
import { Component, OnInit } from "@angular/core";
import { Subject } from "rxjs";
import { PDF_VIEWER } from "../../constant";
import { DataService } from "../../store/services/dataService";

interface DownloadItem{
  name: string;
  url: string;
}
@Component({
  selector: "williams-ui-platform-download",
  templateUrl: "./download.component.html",
  styleUrls: ["./download.component.scss"],
})
export class DownloadComponent implements OnInit {
  data: DownloadItem[] = [];
  downloadableForm: DownloadItem[] = [];
  constructor(private dataService: DataService) {}

  ngOnInit(): void {
    this.dataService.getData().subscribe((jsonData) => {
      if (jsonData) {
        this.data = jsonData.data || [];
        this.downloadableForm = jsonData.downloadableForm || [];
      }
    });
  }
}

<ng-container *ngIf="!customFilter; else customFilterTemplate">
    <div [ngClass]="{ 'show-errors': showErrors }">
        <kendo-combobox
            *ngIf="control; else nonFormContrl"
            #combobox
            [class]="{ 'hide-arrow-button': hideArrowButton }"
            [data]="data"
            [clearButton]="clearButton"
            [disabled]="disabled"
            [readonly]="readonly"
            [filterable]="filterable"
            [textField]="textField"
            [valueField]="valueField"
            [value]="value"
            [valuePrimitive]="valuePrimitive"
            [size]="size"
            [fillMode]="fillMode"
            [rounded]="rounded"
            [formControl]="control"
            [popupSettings]="popupSettings"
            [allowCustom]="allowCustom"
            [virtual]="isVirtual"
            (filterChange)="onFilterChange($event)"
            (valueChange)="onValueChange($event)"
            (blur)="onBlur()"
        >
            <ng-template kendoComboBoxItemTemplate let-dataItem *ngIf="comboboxTemplates?.comboboxListItemTemplate">
                <ng-container *ngTemplateOutlet="comboboxTemplates.comboboxListItemTemplate; context: { dataItem }"></ng-container>
            </ng-template>
        </kendo-combobox>

        <ng-template #nonFormContrl>
            <kendo-combobox
            #combobox
            [class]="{ 'hide-arrow-button': hideArrowButton }"
            [data]="data"
            [clearButton]="clearButton"
            [disabled]="disabled"
            [readonly]="readonly"
            [filterable]="filterable"
            [textField]="textField"
            [valueField]="valueField"
            [value]="value"
            [valuePrimitive]="valuePrimitive"
            [size]="size"
            [fillMode]="fillMode"
            [rounded]="rounded"
            [popupSettings]="popupSettings"
            [allowCustom]="allowCustom"
            [virtual]="isVirtual"
            (filterChange)="onFilterChange($event)"
            (valueChange)="onValueChange($event)"
            (blur)="onBlur()"
        >
            <ng-template kendoComboBoxItemTemplate let-dataItem *ngIf="comboboxTemplates?.comboboxListItemTemplate">
                <ng-container *ngTemplateOutlet="comboboxTemplates.comboboxListItemTemplate; context: { dataItem }"></ng-container>
            </ng-template>
        </kendo-combobox>
        </ng-template>
    </div>
</ng-container>

<ng-template #customFilterTemplate>
    <div [ngClass]="{ 'show-errors': showErrors }">
        <kendo-combobox
            #combobox
            [class]="{ 'hide-arrow-button': hideArrowButton }"
            [data]="data"
            [clearButton]="clearButton"
            [disabled]="disabled"
            [readonly]="readonly"
            [filterable]="filterable"
            [textField]="textField"
            [valueField]="valueField"
            [value]="value"
            [valuePrimitive]="valuePrimitive"
            [size]="size"
            [fillMode]="fillMode"
            [rounded]="rounded"
            [popupSettings]="popupSettings"
            [allowCustom]="allowCustom"
            [virtual]="isVirtual"
            [formControl]="control"
            [kendoDropDownFilter]="customFilterSettings"
            (filterChange)="onFilterChange($event)"
            (valueChange)="onValueChange($event)"
            (blur)="onBlur()"
        >
            <ng-template kendoComboBoxItemTemplate let-dataItem *ngIf="comboboxTemplates?.comboboxListItemTemplate">
                <ng-container *ngTemplateOutlet="comboboxTemplates.comboboxListItemTemplate; context: { dataItem }"></ng-container>
            </ng-template>
        </kendo-combobox>
    </div>
</ng-template>
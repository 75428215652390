import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
    EntityCollectionServiceBase,
    EntityCollectionServiceElementsFactory
} from '@ngrx/data';
import { map, Observable } from 'rxjs';
import { INDEXOFCUSTOMERS } from '../entity-meta-data/entity-metadata';
import { StaticResponse } from '../model/base-response';
import { IndexOfCustomersInterface } from '../model/indexofcustomers.interface';
import { indexofCustomersUrl, pdfUrl } from '../../../api-endpoints';
import {DomSanitizer, SafeResourceUrl} from "@angular/platform-browser";

@Injectable({ providedIn: 'root' })
export class IndexOfCustomersService extends EntityCollectionServiceBase<IndexOfCustomersInterface> {
  private headers = new HttpHeaders({
    'accept': 'application/json',
  });
  constructor(serviceElementsFactory: EntityCollectionServiceElementsFactory, private http: HttpClient,private sanitizer: DomSanitizer) {
    super(INDEXOFCUSTOMERS,serviceElementsFactory);
  }

  fetchFileData(params: any,fileType:string): Observable<any> {
      const url = `${indexofCustomersUrl}/${fileType}`.replace('{location}', params.location);
      console.log("trying");
    return  this.http.get<any>(url, {params, headers: this.headers})
      .pipe(map((res: any) => {
        return res.data;
    }));
  }

  fetchPdfFileData(fileName: string, location: string, fileLocation?: string): Observable<any> {
        const url = pdfUrl.replace('{location}', location) + fileName;
        console.log(url);
        const headers = new HttpHeaders({
            'accept': 'application/json'
        });
        const params: any = {};
        if (fileLocation) {
            params.fileLocation = fileLocation;
        }
        else{
            params.fileLocation = "";
        }
        return this.http.get<StaticResponse<Array<any>>>(url, { headers, params })
            .pipe(map((res: StaticResponse<Array<any>>) => {
                return res.data;
            }));
    }

    fetchImageFileData(fileName: string, location: string, fileLocation?: string): Observable<SafeResourceUrl> {
        const url = pdfUrl.replace('{location}', location) + fileName;
        const headers = new HttpHeaders({
            'accept': 'application/json'
        });
        const params: any = {};
        if (fileLocation) {
            params.fileLocation = fileLocation;
        } else {
            params.fileLocation = "";
        }
        return this.http.get<StaticResponse<Array<any>>>(url, { headers, params })
            .pipe(
                map((res: StaticResponse<Array<any>>) => {
                    const base64String = res.data[0]; // Assuming the base64 string is in res.data[0]
                    return this.convertToSafeResourceUrl(base64String);
                })
            );
    }

    convertToSafeResourceUrl(base64String: string): SafeResourceUrl {
      console.log(this.sanitizer.bypassSecurityTrustResourceUrl('data:application/pdf;base64,' + base64String));
        return this.sanitizer.bypassSecurityTrustResourceUrl('data:application/pdf;base64,' + base64String);
    }
}
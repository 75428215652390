import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Subject } from "rxjs";
import { Router } from "@angular/router";
import {
  LOCATIONS,
  VIEW_DOWNLOAD_LOCATIONS_TITLE,
  FILE_TYPE,
  VIEW,
  DOWNLOAD,
  PDF_VIEWER,
} from "../constant";
import { locationsUrl } from "../../api-endpoints";
import {environment} from "../../environments/environment";

@Component({
  selector: "williams-ui-platform-locations",
  templateUrl: "./locations.component.html",
  styleUrls: ["./locations.component.scss"],
})
export class LocationsComponent implements OnInit {
  eyeIcon = "assets/w-ui-angular/images/eye.svg";
  hoveredEyeIcon = "assets/w-ui-angular/images/eye.svg";
  activedEyeIcon = "assets/w-ui-angular/images/eye-white.svg";
  downLoadIcon = "assets/w-ui-angular/images/downloadarrow.svg";
  hovereddownLoadIcon = "assets/w-ui-angular/images/downloadarrow.svg";
  activedownLoadIcon = "assets/w-ui-angular/images/downloadarrowWhite.svg";
  form!: FormGroup;
  fileTypeDropdownData: Array<any> = [
    { name: "Portable Document Format (PDF)", value: "pdf" },
    { name: "Comma Separated Values (CSV)", value: "csv" },
  ];
  selectedFileType: string = "pdf";
  fileData!: string;
  destroy$: Subject<boolean> = new Subject<boolean>();
  LOCATIONS = LOCATIONS;
  VIEW_DOWNLOAD_LOCATIONS_TITLE = VIEW_DOWNLOAD_LOCATIONS_TITLE;
  FILE_TYPE = FILE_TYPE;
  VIEW = VIEW;
  DOWNLOAD = DOWNLOAD;
  newLocationsUrl = locationsUrl.replace("{location}", environment.fileLocation);
  constructor(private fb: FormBuilder, private router: Router) {
    this.formInit();
  }

  formInit() {
    this.form = this.fb.group({
      filetype: ["pdf", [Validators.required]],
    });
  }

  ngOnInit(): void {}

  selectionChange(event: any) {
    this.selectedFileType = event.value.value;
  }

  view() {
    if (this.selectedFileType === "csv") {
      window.open(
        `${this.newLocationsUrl}/Locations?fileType=CSV`,
        "_blank"
      );
    } else {
      this.router.navigate([]).then((result) => {
        window.open(`${PDF_VIEWER}?fileName=Locations`, "_blank");
      });
    }
  }

  download() {
    console.log(this.selectedFileType);
    if (this.selectedFileType === "csv") {
      window.location.href =
        `${this.newLocationsUrl}/Locations?fileType=CSV`;
    } else {
      window.location.href =
          `${this.newLocationsUrl}/Locations?fileType=PDF`;
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }
}

import {
  AfterViewInit,
  Component,
  ElementRef,
  HostListener,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild
} from "@angular/core";
import { Router } from "@angular/router";
import { Subject, takeUntil } from "rxjs";
import {
  CRITICAL_NOTICES,
  SHOW_ALL,
  THERE_ARE_NO_CRITICAL_NOTICES,
  PLANNED_SERVICE_OUTAGE,
  THERE_ARE_NO_PLANNED_SERVICE,
  CONTACTS,
  ADDRESS_1,
  ADDRESS_2,
  ADDRESS_3,
  ADDRESS_4,
  VIEW_ALL_CONTACTS,
} from "../constant";
import { NoticesInterface } from "../store/model/notices.interface";
import { CriticalNoticesService } from "../store/services/critical-notices.service";
import { IndexOfCustomersService } from "../store/services/index-of-customers.service";
import { PlannedOutagesService } from "../store/services/planned-service-outages.service";
import { dateFormat } from "../utils/date.tranfrom";
import {environment} from "../../environments/environment";
import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser";
import {NgxExtendedPdfViewerComponent} from "ngx-extended-pdf-viewer";

@Component({
  selector: "williams-ui-platform-home",
  templateUrl: "./home.component.html",
  styleUrls: ["./home.component.scss"],
})
export class HomeComponent implements OnInit, OnDestroy {
  @ViewChild('pdfViewer1') pdfViewer1!: NgxExtendedPdfViewerComponent;
  @ViewChild('plannedServiceOutageContainer') plannedServiceOutageContainer!: ElementRef;
  criticalBadge = {
    "background": "#F688271F",
    "border-color": "#F68827",
    "color": "#0e1b25 !important",
    "font-size": "10px !important",
  };

  plannedBadge = {
    "background": "rgba(91, 96, 171, 0.12)",
    "border-color": "#5B60AB",
    "color": "#0F2258 !important",
  };
  navContactIcon = "assets/w-ui-angular/images/nav-expand.svg";
  navIcon = "assets/w-ui-angular/images/nav-expand.svg";
  navExpandIcon = "assets/w-ui-angular/images/nav-expand.svg";
  hoveredNavExpandIcon = "assets/w-ui-angular/images/nav-expand.svg";
  activeNavExpandIcon = "assets/w-ui-angular/images/navExpandWhite.svg";
  pageable: boolean = true;
  public width = "100%";
  public height = "230px";
  private previousItem = 0;
  notices: any;
  noticeCard = [1, 2];
  activeIndexValue = 0;
  noticeData: NoticesInterface[] = [];
  plannedOutageData: NoticesInterface[] = [];
  imageUrl = '';
  headerImageUrl = "";
  destroy$: Subject<boolean> = new Subject<boolean>();
  CRITICAL_NOTICES = CRITICAL_NOTICES;
  SHOW_ALL = SHOW_ALL;
  THERE_ARE_NO_CRITICAL_NOTICES = THERE_ARE_NO_CRITICAL_NOTICES;
  PLANNED_SERVICE_OUTAGE = PLANNED_SERVICE_OUTAGE;
  THERE_ARE_NO_PLANNED_SERVICE = THERE_ARE_NO_PLANNED_SERVICE;
  CONTACTS = CONTACTS;
  ADDRESS_1 = environment.shortAddress1;
  ADDRESS_2 = environment.shortAddress2;
  ADDRESS_3 = environment.shortAddress3;
  ADDRESS_4 = environment.shortAddress4;
  VIEW_ALL_CONTACTS = VIEW_ALL_CONTACTS;
  showCriticalNotices: boolean = false;
  showPlannedOutageNotices: boolean = false;
  plannedServiceOutageCardWidth = 585.5
  showPdf: boolean = true;
  isPdfLoaded = false;
  isImageModalOpen = false;

  @HostListener("window:resize", ["$event"])
  onResize(event: any) {
    this.calculatePlannedOutageCardWith();
  }

  constructor(
    private criticalNoticeService: CriticalNoticesService,
    private router: Router,
    private plannedOutageService: PlannedOutagesService,
    private customerService: IndexOfCustomersService,
    private http: HttpClient,
    private sanitizer: DomSanitizer,
  ) {}

  ngOnInit(): void {
    this.showPdf = true;
    // this.loadPdf();
    this.plannedOutageService
        .fetchPlannedServiceOutages({
          id: environment.ID,
          noticetype: "P",
          pageNo: 0,
          pageSize: 4,
          sort: "begDate",
          sortOrder: "DESC",
        })
        .pipe(takeUntil(this.destroy$))
        .subscribe((res) => {
          this.plannedOutageData = res;
          this.showPlannedOutageNotices = true;
          setTimeout(() => this.calculatePlannedOutageCardWith());
        });
    this.customerService
        .fetchImageFileData('sitemap.png', environment.fileLocation)
        .pipe(takeUntil(this.destroy$))
        .subscribe((res: any) => {
          this.imageUrl = res;
        });
    this.customerService
        .fetchImageFileData('header.png', environment.fileLocation)
        .pipe(takeUntil(this.destroy$))
        .subscribe((res: any) => {
            this.headerImageUrl = res;
        });
    // this.mockCustomerService.fetchImageFileData('discoverymap.png', '').subscribe(
    //     (res: SafeResourceUrl) => {
    //       console.log('Image fetched successfully:', res);
    //       this.imageUrl = this.validateBase64(res as string) ? res as string : this.encodeToBase64(res as string);
    //       this.isPdfLoaded = true;
    //       console.log(this.imageUrl);
    //     },
    //     (err: any) => {
    //       console.error('Error fetching image:', err);
    //     }
    // );


    this.fetchCriticalNoticesData();

    this.notices = this.noticeData;
    this.noticeCard = this.noticeData.length === 4 ? [1, 2] : [1];
    this.showCriticalNotices = true;
    this.showPlannedOutageNotices = true;

    setTimeout(() => this.calculatePlannedOutageCardWith());
  }
  // loadPdf(): void {
  //   this.mockCustomerService.fetchPdfFileData('', '').subscribe(
  //       (res: any) => {
  //         this.pdfUrl = this.validateBase64(res[0]) ? res[0] : this.encodeToBase64(res[0]);
  //         this.isPdfLoaded = true;
  //         console.log(this.pdfUrl);
  //         this.pdfUrl2 = this.pdfUrl;
  //         console.log('PDF URL:', this.pdfUrl);
  //         if (this.pdfViewer1) {
  //           this.pdfViewer1.src = this.pdfUrl;
  //         }
  //       },
  //       (err: HttpErrorResponse) => {
  //         console.error('Error loading PDF:', err);
  //         if (err.status === 404) {
  //           console.error('PDF file not found. Please ensure the file is placed in the correct directory.');
  //         }
  //       }
  //   );
  // }

  validateBase64(str: string): boolean {
    try {
      return btoa(atob(str)) === str;
    } catch (err) {
      return false;
    }
  }
  //
  encodeToBase64(str: string): string {
    return btoa(unescape(encodeURIComponent(str)));
  }

  openImageModal(): void {
    this.isImageModalOpen = true;
    console.log(this.imageUrl);
    console.log('Image Model Open');
  }

  closeImageModal(): void {
    this.isImageModalOpen = false;
    console.log('Image Model Closed');
    console.log('Pdf Url Open:', this.imageUrl);
  }

  // onPdfLoad(): void {
  //   this.isPdfLoaded = true;
  //   console.log('PDF Loaded');
  // }

  calculatePlannedOutageCardWith() {
    if(this.showPlannedOutageNotices && this.plannedServiceOutageContainer) {
      this.plannedServiceOutageCardWidth = (this.plannedServiceOutageContainer.nativeElement.offsetWidth - 88) / 2;
    }
  }

  fetchCriticalNoticesData(): void {
    const query = {
      id: environment.ID,
      noticetype: "C",
      pageNo: "0",
      pageSize: "4",
      sort: "begDate",
      sortOrder: "DESC",
    };
    this.criticalNoticeService
      .getWithQuery(query)
      .pipe(takeUntil(this.destroy$))
      .subscribe((res) => {
        this.noticeData = res;
        this.notices = this.noticeData.slice(0,2);
        this.noticeCard = this.noticeData.length === 4 ? [1, 2] : [1];
        this.showCriticalNotices = true;
      });
  }

  onChange(e: any) {
    if (e.item > this.previousItem) {
      this.notices = this.noticeData.slice(2, 4);
    } else {
      this.notices = this.noticeData.slice(0, 2);
    }
    this.previousItem = e.item;
  }

  navigateToNotices(type: string) {
    window.scroll({ top: 0,left: 0,behavior: 'smooth' });
    if (type === "C") {
      this.router.navigate(["notices/critical-notices"]);
    } else {
      this.router.navigate(["notices/planned-service-outages"]);
    }
  }

  navigateToNoticesDetails(event: any) {
    window.scroll({ top: 0,left: 0,behavior: 'smooth' });
    this.router.navigate(["notices/critical-notices/critical-notice-detail"], {
      queryParams: { id: event.id }
    });
  }

  navigateToPlannedNoticesDetails(event: any) {
    window.scroll({ top: 0,left: 0,behavior: 'smooth' });
    this.router.navigate(["notices/planned-service-outages/planned-service-outages-detail"], {
      queryParams: { id: event.id },
    });
  }

  transFormDate(date:string){
    return dateFormat(date);
 }
 
  navigateToContact() {
    window.scroll({ top: 0,left: 0,behavior: 'smooth' });
    this.router.navigate(["contacts"]);
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  protected readonly open = open;
  protected readonly environment = environment;
}
